import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/mcruz/Documents/Developer/gatsby/appuro-viaespiga-pt/src/components/layout.js";
import MdxLayout from "../components/mdxLayout.js";
import MdxCoverLayout from "../components/mdxCoverLayout.js";
import ContactsPage from "../components/contacts.js";
import Seo from '../components/seo.js';
import ogImage from '../../static/og-image/ogg-image-default.png';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title="Contactos" description="A Via Espiga surgiu em 1992, procurando acompanhar, desde esse momento, os mais elevados padrões da moda internacional, colocando-os, ano após ano, ao dispor do mercado português. Loja de vestuário situada na Cidade de Espinho." image={'https://www.viaespiga.com' + ogImage} siteUrl={'https://www.viaespiga.com' + props.pageContext.frontmatter.path} mdxType="Seo" />
    <MdxCoverLayout mdxType="MdxCoverLayout" />
    <MdxLayout mdxType="MdxLayout">
      <h1>{props.pageContext.frontmatter.title}</h1>
      <strong>Via Espiga</strong><br />
Rua 23, 210<br />
4500-142 Espinho<br />
Portugal<br />
Loc: 41.007268, -8.643413<br />
Telefone: 227311205<br />
Email: viaespiga@viaespiga.com
    </MdxLayout>
    <div style={{
      maxWidth: 900,
      margin: '0 auto'
    }}>
    <ContactsPage mdxType="ContactsPage" />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      